





















































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant"

@Component({
    components:{},
})
export default class Route extends Vue {
    colSpan = 8;

    get AdminRouter(){
        return AdminRouter;
    }
}
